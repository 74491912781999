<template>
    <el-card class="v_task_list">
        <div class="g-flex"  slot="header">
            <span>后台任务列表</span>
        </div>
        <el-form :inline="true" size="mini" :model="query">
          <!-- 搜索 -->
          <el-form-item label="任务状态：">
            <el-select v-model="query.status" @change="getList()">
              <el-option label="全部" value="ALL"></el-option>
              <el-option label="待支付" value="10"></el-option>
              <el-option label="待审核" value="20"></el-option>
              <el-option label="进行中" value="30"></el-option>
              <el-option label="已完成" value="40"></el-option>
              <el-option label="已取消" value="0"></el-option>
            </el-select>
          </el-form-item>

        <el-form-item label="平台类型：">
            <el-select v-model="query.platform_id" @change="getList()">
              <el-option :label="item.title" :value="item.id" v-for="(item, index) in platFormList" :key="index" ></el-option>
            </el-select>
          </el-form-item>

            <el-form-item label="时间：">
                <el-date-picker v-model="query.date_time" @change="getList()" type="datetimerange"
                                :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>    

          <el-form-item label="店铺名称">
             <el-input v-model="query.shop_title" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
          </el-form-item>
            <el-form-item>
                <el-select slot="label" v-model="query.search_key" placeholder="查询">
                    <el-option label="商家手机号" value="mobile"></el-option>
                    <el-option label="任务编号" value="id"></el-option>
                </el-select>
                <el-row>
                    <el-col :span="13">
                        <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-button type="info" @click="resetData">重置</el-button>
                        <el-button type="primary" @click="getList()">查询</el-button>
                    </el-col>
                </el-row>
            </el-form-item>

        </el-form>
        <el-table height="550" stripe size="mini" v-loading="table.loading" :data="table.rows" border>
            <el-table-column label="商家手机号" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.user.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column label="任务id">
                <template slot-scope="scope">
                    <span>{{scope.row.id}}</span>
                </template>
            </el-table-column>

            <el-table-column label="商家id">
                <template slot-scope="scope">
                    <span>{{scope.row.user_id}}</span>
                </template>
            </el-table-column>
            <el-table-column label="总金额"  :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.total_price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="退款金额"  :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{ scope.row.refund_price }}</span>
                </template>
            </el-table-column>

            <el-table-column label="店铺名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                    <span>{{scope.row.userShop.title}}</span>
                </template>
            </el-table-column>

            <el-table-column label="商城类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                    <span>{{ scope.row.platform.title }}</span>
                </template>
            </el-table-column> 


            <el-table-column label="任务类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                    <span>{{ scope.row.platformPlay.title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="好评方式" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                    <span>{{ scope.row.task_type.title }}</span>
                </template>
            </el-table-column>

            <el-table-column label="任务单数" width="300" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <el-row class="g-fsize16">
                  <el-col :span="8">
                    <span>共 {{ scope.row.search_keyword.reduce((total, prev) => { return total + Number(prev.num) }, 0 )  }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>已取消 {{ scope.row.task_order_info.s0 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>待接单 {{ scope.row.task_order_info.s10 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>待审核 {{ scope.row.task_order_info.s20 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>操作中 {{ scope.row.task_order_info.s30 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>待返款 {{ scope.row.task_order_info.s40 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>待收货 {{ scope.row.task_order_info.s50 }}</span>
                  </el-col>
                  <el-col :span="8">
                    <span>待完成 {{ scope.row.task_order_info.s60 }}</span>
                  </el-col>
                   <el-col :span="8">
                    <span>已完成 {{ scope.row.task_order_info.s70 }}</span>
                  </el-col>
                </el-row>
                </template>
            </el-table-column>

            <el-table-column label="状态" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span v-show="scope.row.status === 0" class="g-grey">已取消</span>
                    <span v-show="scope.row.status === 10" class="g-red">待支付</span>
                    <span v-show="scope.row.status === 20" class="g-yellow">待审核</span>
                    <span v-show="scope.row.status === 30" class="g-green">进行中</span>
                    <span v-show="scope.row.status === 40" class="g-purple">已完成</span>
                </template>
            </el-table-column>

            <el-table-column label="创建时间" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
                </template>
            </el-table-column>
          <el-table-column label="操作" width="300" fixed="right">
            <template slot-scope="scope">
              <el-button  size="mini" type="info" v-show="scope.row.task_order_info.s10 != 0 && auth.taskpass" @click="setCancelOrder(scope.row)">取消任务</el-button>
              <el-button  size="mini" v-show="scope.row.status === 20 && auth.taskpass" type="primary" @click="setForm(scope.row, 1)">审核</el-button>
              <el-button  size="mini" type="warning" @click="setForm(scope.row, 2)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
   
    <!--新增和编辑-->
    <el-dialog :close-on-press-escape="false" :title="seeStatus === 1 ? '审核任务': '查看详情'" class="s-task-dialog" top="80px" width="1100px" :close-on-click-modal="false" :visible.sync="form">
                <el-form :model="formData" label-width="140px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 选择类型 -->
                    <el-tab-pane label="选择类型" name="1">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="商城类型:">
                              <span>{{formData.platform.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺名称:">
                              <span>{{formData.userShop.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="店铺账号:">
                              <span>{{formData.userShop.account}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.platformPlay.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24" v-show="formData.entrance">
                            <el-form-item label="活动单类型:">
                              <span>{{formData.entrance}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="本金返款模式:">
                              <span v-if="formData.fk_mode == 0">平台返款</span>
                              <span v-if="formData.fk_mode == 1">商家返款</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 试用活动商品信息 -->
                    <el-tab-pane label="试用活动商品信息" name="2">
                      <el-row>
                        <el-col :span="24">
                            <el-form-item label="商品链接:">
                              <span>{{formData.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="商品标题:">
                              <span>{{formData.title}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="详情页验证关键词:">
                              <span>{{formData.detail_keyword}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="宝贝示例图片:">
                              <el-row :gutter="20">
                                <el-col :span="3" v-for="(item,index) in formData.img" :key="index">
                                  <el-image  style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索排序方式:">
                              <span>{{formData.search_sort}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="筛选条件备注:">
                              <span>{{formData.search_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="搜索展示价:">
                              <span>{{formData.search_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="拍下件数:">
                              <span>{{formData.buy_num}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="6">
                            <el-form-item label="宝贝下单价:">
                              <span>{{formData.actual_price}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item label="是否包邮:">
                              <span>{{formData.is_free ? '包邮':'不包邮' }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-show="formData.entrance">
                          <el-col :span="16" v-show="formData.entrance === '淘口令'">
                            <el-form-item label="淘口令:">
                              <span>{{formData.tkl}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="16">
                            <el-form-item label="入口描述:" >
                              <span>{{formData.entrance_remark}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- v-show="formData.entrance" -->
                        <el-row v-show="formData.entrance" >
                          <el-col :span="15">
                            <el-form-item label="步骤截图:">
                              <el-col :span="3" v-for="(item,index) in formData.entrance_pic" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="[formData.entrance_pic]"></el-image>
                                </el-col>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row v-for="(item, index) in formData.goods" :key="index">
                          <div class="s-hr" v-show="formData.goods.length > 1"></div>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品链接' + (index+1) + ':'">
                              <span>{{item.url}}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item :label="'附加商品图片'+ (index+1) + ':'">
                               <el-image  style="width: 100px; height: 100px" fit="cover" :src="item.img" :preview-src-list="[item.img]"></el-image>
                            </el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item :label="'附加商品价格' + (index+1) + ':'">
                              <span>{{item.price}}元</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="10">
                            <el-form-item :label="'附加商品拍下件数' + (index+1) + ':'">
                              <span>{{item.num}}件</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 选择任务类型和单数 -->
                    <el-tab-pane label="选择任务类型和单数" name="3">
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务类型:">
                              <span>{{formData.task_type.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.entrance=='淘口令' && formData.tkl">
                            <el-form-item label="淘口令:">
                              <span>{{ formData.tkl }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.platform_play_id==306 && formData.tkl">
                            <el-form-item label="入口链接:">
                              <span>{{ formData.tkl }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.platform_play_id==306 && formData.entrance_code">
                            <el-form-item label="入口二维码:">
                                <el-image style="width: 100px; height: 100px" fit="cover" :src="formData.entrance_code" :preview-src-list="[formData.entrance_code]"></el-image>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24" v-if="formData.entrance_store">
                            <el-form-item label="进店链接:">
                              <span>{{ formData.entrance_store }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <!-- 普通好评 -->
                        <div v-if="formData.task_type.id == 10">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 文字好评 -->
                        <div v-if="formData.task_type.id == 11">
                          <el-row v-for="(item,index) in formData.search_keyword" :key="index" class="g-padtop20 g-padbtom20">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'指定文字任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>

                        <!-- 图片好评 -->
                        <div v-if="formData.task_type.id == 12">
                          <el-row :gutter="30" v-for="(item,index) in formData.search_keyword" class="g-padtop20 g-padbtom20" :key="index">
                            <el-col :span="8">
                              <el-form-item :label="'搜索关键词'+(index+1)+':'">
                                <el-input placeholder="请输入内容"  v-model="item.keyword" readonly></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item :label="'图片好评任务数量'+(index+1)+':'">
                                <span>{{item.num}}单</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item :label="'图片好评商品规格'+(index+1)+':'">
                                <span>{{item.size}}</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'图片好评晒图'+(index+1)+':'">
                                <el-row :gutter="30">
                                  <el-col :span="4" v-for="(item2, index2) in item.img" :key="index2">
                                    <el-image style="width: 100px; height: 100px" fit="cover" :src="item2" :preview-src-list="item.img"></el-image>
                                  </el-col>
                                </el-row>
                              </el-form-item>
                            </el-col>
                            <el-col :span="24">
                              <el-form-item :label="'指定文字内容'+(index+1)+':'">
                                <span>{{item.reviews}}</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </div>
                    </el-tab-pane>
                    <!-- 试用活动要求 -->
                    <el-tab-pane label="试用活动要求" name="4">
                        <el-row v-show="formData.xdsj.title">
                          <el-col :span="24">
                            <el-form-item label="下单时间:">
                              <span>{{formData.xdsj.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="货比商品:">
                              <span>{{formData.huobi.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="浏览副宝贝:">
                              <span>{{formData.liulan.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="主宝贝浏览:">
                              <span>{{formData.main_liulan.map(item => item.title).join(',')}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="付款方式:">
                              <span>{{formData.pay_type}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="操作中截图:">
                              <span>{{formData.screenshot == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="确认收货截图:">
                              <span>{{formData.screenshot_arrival == '0' ? '不截图': '截图' }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明:">
                              <span>{{formData.explain}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="特殊说明示例图:">
                              <el-row :gutter="30">
                                <el-col :span="3" v-for="(item, index) in formData.explain_img" :key="index">
                                  <el-image style="width: 100px; height: 100px" fit="cover" :src="item" :preview-src-list="formData.explain_img"></el-image>
                                </el-col>
                              </el-row>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="活动参与人群标签与资格" name="5">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="商家审号服务:">
                              <span>{{formData.task_pass ? '开启' : '关闭'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="开通花呗:">
                              <span>{{formData.credit_pay ? '需要': '不需要'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="信誉要求:">
                              <span>{{formData.xinyu.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="性别限制:">
                              <span>{{formData.sex === 0 ? '不限制': (formData.sex === 1 ? '男号': '女号' )}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="年龄限制:">
                              <span>{{formData.age === 0 ? '不限制': ((formData.age === 1) ? '18~25岁': ((formData.age === 2) ? '26-35岁' : '35岁以上')) }}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="地区限制:">
                              <span>{{formData.region}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="店铺申请限制:">
                              <span v-show="formData.shop_limit === 0">不限制</span>
                              <span v-show="formData.shop_limit !== 0">{{formData.shop_limit}} 天内禁止申请该店铺的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布申请限制:">
                              <span v-show="formData.account_limit === 0">不限制</span>
                              <span v-show="formData.account_limit !== 0">{{formData.account_limit}}天 内禁止申请我账号下发布的试用商品</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                         <el-row>
                          <el-col :span="24">
                            <el-form-item label="加赏任务佣金:">
                              <span>{{formData.reward}}元 / 单</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </el-tab-pane>

                    <el-tab-pane label="试用活动发布方式" name="6">
                      <el-row>
                          <el-col :span="24">
                            <el-form-item label="发布方式:">
                              <span>{{formData.time_type === 1 ? '立即发布' : '小时发布'}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 1">
                          <el-col :span="24">
                            <el-form-item label="任务发布时间:">
                              <span>{{ formatDate(formData.time_val, 'yyyy-MM-dd hh:mm')  }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row v-show="formData.time_type === 2">
                          <el-col :span="24">
                            <el-form-item label="任务开始日期:">
                              <span>{{ formData.start_date }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.startTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-form-item label="任务开始时刻:">
                              <span>{{ formData.endTime +':00' }} </span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务发布间隔:">
                              <span>{{Number(formData.time_interval)}} 分钟</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="24">
                            <el-form-item label="任务回收时长:">
                              <span>{{ taskTimeList.find(item => item.value === formData.close_time).name}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        
                    </el-tab-pane>
                  </el-tabs>

                  <el-row>
                    <el-col :span="18">
                      <el-form-item label="审核不通过理由:">
                         <el-input placeholder="请输入审核不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="form = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="form = false" v-show="seeStatus === 2">确定</el-button>
            <el-button type="danger"  @click="apiTaskNoPassHandel" v-show="seeStatus === 1">审核不通过</el-button>
            <el-button type="success" v-show="seeStatus === 1" @click="apiTaskPassHandel">审核通过</el-button>
        </div>
    </el-dialog>
   
    </el-card>
    
</template>

<script>
  import { apiGetTaskList, apiGetShopTypeList, apiTaskPay, apiTaskPass ,apiTaskNoPass, apiCancelTask } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
    export default {
      name: "LogList",
       async created() {
          await this.apiGetShopTypeListHandel()
          await this.getList()
        },
        data(){
            return {
                auth: {
                  taskpass: this.$global.ApiList.includes(23), //任务审核
                  taskagain: this.$global.ApiList.includes(24), //再次发布任务
                  taskpay: this.$global.ApiList.includes(25), //任务支付
                },
                // 1 审核 2 查看详情
                seeStatus: 1,

                activeName:'1',
                platFormList: [ { id: 'ALL', title: '全部'} ],
                query: {
                    type: 0,
                    status: 'ALL',
                    platform_id: 'ALL',
                    shop_title:'',
                    date_time: [],
                    search_key: 'mobile', search_val: '',
                    page: 1,
                    limit: 15
                },
                table: {loading: false, total: 0, rows: []},
                form: false,
                title: '开心掌柜',
                // 任务回收时间
                taskTimeList: [
                  { name: '2小时', value: 2 },
                  { name: '4小时', value: 4 },
                  { name: '8小时', value: 8 },
                  { name: '12小时', value: 12 },
                  { name: '24小时', value: 24 },
                  { name: '48小时', value: 48 },
                  { name: '当天24点', value: 0 },
                ],
                formData: {
                  img: [],
                  remark: '',
                  userShop: { account : '' },
                  platform: { title: '' },
                  platformPlay: { title: '' },
                  goods: [],
                  task_type: { title: '' },
                  search_keyword: [],
                  entrance_pic: [],
                  xdsj: { title: '' },
                  huobi: { title: '' },
                  liulan: { title: '' },
                  main_liulan: [{ title: '' }],
                  explain_img: [],
                  xinyu: { title: '' },
                  shop_limit: 0,
                  account_limit: 0,
                  close_time: 0,
                  time_interval: 0,
                  time_val: 0
                  
                },
            }
        },
        methods: {
          formatDate,
        // 点击取消任务
        setCancelOrder(item) {
          this.$confirm('此操作会将该任务取消, 是否继续?', '取消任务', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.apiCancelTaskHandel(item)
            }).catch(() => {});
        },

        // 取消任务
        async apiCancelTaskHandel(item) {
          let loading = this.$loading()
          const { success, data } = await apiCancelTask({ id: item.id }).catch(() => { loading.close() })
          loading.close()
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
        },
        // 支付任务金额
        async apiTaskPayHandel(obj = { id: '' }) {
          this.table.loading = true
          const { success, data } = await apiTaskPay({ id: obj.id }).catch(() => {this.table.loading = false })
          this.table.loading = false
          if(!success) return
          this.$message.success(data.msg)
          this.getList(false)
          console.log(data)
        },

          resetDialog() {
            this.activeName = '1'
            this.form = false
          },

          // 审核通过
          async apiTaskPassHandel() {
            this.table.loading = true
            const { success, data } = await apiTaskPass({ id: this.formData.id }).catch(() => {this.table.loading = false })
            this.table.loading = false
            if(!success) return
            this.$message.success(data.msg)
            this.resetDialog()
            this.getList(false)
            console.log(data)
          },
          // 审核不通过
          async apiTaskNoPassHandel() {
            if(!this.formData.remark) return this.$message.error('请输入审核不通过理由')
            this.table.loading = true
            const { success, data } = await apiTaskNoPass({ id: this.formData.id, remark: this.formData.remark }).catch(() => {this.table.loading = false })
            this.table.loading = false
            if(!success) return
            this.$message.success(data.msg)
            this.resetDialog()
            this.getList(false)
            console.log(data)
          },
          //设置form
          setForm(formData = { id: '' }, seeStatus = 2) {
            console.log(formData)
            this.formData = JSON.parse(JSON.stringify(formData)) 
            this.seeStatus = seeStatus
            this.form = true
          },
          // 获取平台列表
          async apiGetShopTypeListHandel() {
            const { success, data } = await apiGetShopTypeList()
            if(!success) return
            this.platFormList = this.platFormList.concat(data.list)
          },
          //获取列表
          async getList(pageInit = true) {
              if (pageInit) this.query.page = 1
              this.table.loading = true
              const { success, data } = await apiGetTaskList(this.query).catch(() => {this.table.loading = false })
              this.table.loading = false
              if(!success) return
              this.table.rows = data.rows
              this.table.total = data.total
          },
          //每页数量改变
          sizeChange(limit) {
              this.query.limit = limit
              this.getList()
          },
          resetData() {
            this.query = {
                type: 0,
                status: 'ALL',
                platform_id: 'ALL',
                shop_title:'',
                date_time: [],
                search_key: 'mobile', search_val: '',
                page: 1,
                limit: 15
            }
            this.getList()
          }
        }
    }
</script>
<style lang="scss">
  .v_task_list {
    .s-task-dialog {
      .el-form {
        .el-form-item__label {
          font-weight: bold;
        }
        .el-form-item__content {
          color: $grey;
        }
      }
    }
    .s-hr {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      border-radius: 2px;
      background: #e2e3e6;
    }
  }
</style>